import cards from './cards.js'
import auth from '@/actions/authorizations/index.js'

// A non-member may be able to view the board depending on the board's visibility setting.
// const PERMISSION_ERROR = 'Make sure you are a member of this board'
const PERMISSION_ERROR = "Authorization required!"

export default class {
  static parentBadge(project) {
    // if (!projectCores) {
    //   // TODO: This is probably not required anymore given the existense of boardBar.
    //   // Not sure if this is a good idea anyway because it might be misleading when our server is down.
    //   return cards.badge({
    //     // This may happen due to two reasons:
    //     // - The board is visible to the user (e.g. visible to users in the same organization) but
    //     //   the user is not a member of the board.
    //     // - The user has not performed authorization.
    //     text: PERMISSION_ERROR
    //   })
    // }

    // const project = projectCores[scopeCard.shapeUp.projectId]
    // if (!project) {
    //   return cards.badge({
    //     text: "Project not found"
    //   })
    // }

    return cards.badge({
      text: `Project: ${project.name.truncate(20)}`,
      // color: $tpu.styles.projectColor(project),
    })
  }

  static childBadge(scopeCard) {
    const taskCardIds = scopeCard.shapeUp.taskCardIds

    // if (!taskCardIds) {
    //   return cards.badge()
    // }

    const count = taskCardIds ? taskCardIds.length : 0
    return cards.badge({
      text: `${count} tasks`
    })
  }

  static parentDetailBadge(project, board) {
    return cards.badge(
      {
        title: "Parent (Project)",
        text: `${project.name} »`,
        // text: `${project.name} ☩ ✥ ✎ ❯ » ➔ 🖊 🖍 » ↵ ↵ ⤸ ↺ ↻`,
        // icon: $tpu.icons.openInBrowser,
        // text: `Project: ${project.name}`,
        // color: $tpu.styles.projectColor(project),
        callback: (t) => {
          this._openProject(project, t, board)
        }
      }
    )
  }

  static typeDetailBadge(scopeCard, projectCores, project) {
    return cards.badge(
      {
        title: "Type",
        text: "Scope ↵",
        callback: (t) => {
          this._badgeLinkActions(scopeCard, projectCores, project, t)
        }
      }
    )
  }

  static _openProject(project, t, board) {
    $tpu.r.data.freshUpdateMemberBoardData(t, (data) => {
      data.projectId = project.id
    }).then(() => {
      auth.conditionalLaunch(t, board, true)
      t.hideCard()
    })
  }

  static _badgeLinkActions(scopeCard, projectCores, project, t) {
    const options = [
      {
        text: 'Change project...',
        callback: (t) => {
          cards.attachToProject(scopeCard, projectCores, t)
        }
      },
      {
        text: 'Detach from project',
        callback: (t) => {
          $tpu.r.scopes.unlink(scopeCard.id, project.id, t).then(() => {
            t.showCard(scopeCard.id)
          })
        }
      }
    ]

    return t.popup({
      title: 'Actions',
      items: options
    });
  }

  static cardBackSection(scopeCard, project, t) {
    return {
      title: 'Shape Up',
      // title: 'Scoped Tasks',
      // title: `Scope: ${scopeCard.name}`,
      // title: `${scopeCard.shapeUp.taskCardIds.length} tasks`,
      icon: $tpu.icons.shapeupBlack,
      // icon: {
      //   dark: $tpu.icons.shapeupWhite,
      //   light: $tpu.icons.shapeupBlack,
      // },
      content: {
        type: 'iframe',
        url: t.signUrl(`./projects/${project.id}/scopes/${scopeCard.id}?view=attachment`),
        height: 230, // Max height is 1500.
      }
    }
  }

//   static detailBadgesPopupItems(card) {
//     return [
//       {
//         text: 'Change color',
//         callback: (t) => {
//           return t.popup({
//             title: 'Pick a color',
//             items: this.badgeColorPopupItems(card)
//           });
//         }
//       },
//       {
//         text: 'Unset as Project',
//         // condition: 'edit',
//         callback: (t) => {
//           $tpu.epics.destroy(card, t).then(() => {
//             t.showCard(card.id)
//           })
//         }
//       }
//     ];
//   }

//   // static cardBackSectionPopupItems(card, epicStatuses) {
//   //   return [
//   //     {
//   //       text: 'Change status',
//   //       callback: (t) => {
//   //         return t.popup({
//   //           title: 'Pick a status',
//   //           items: this.badgeColorPopupItems(card)
//   //         });
//   //       }
//   //     }
//   //   ];
//   // }

//   static badgeColorPopupItems(card) {
//     const array = Array.from($tpu.epics.colorOptions).map(([key, value]) => {
//       return {
//         text: value,
//         callback: (t) => {
//           $tpu.epics.update(card, { badgeColor: key }, t).then(() => {
//             t.showCard(card.id)
//           })
//         }
//       }
//     })

//     return array
//   }
}
